<template>
  <div class="total">
    <el-button
      class="margin-tb-sm"
      type="primary"
      size="mini"
      @click="JumpUrl('/InternalManagement/addNotice')"
      style="
        position: absolute;
        z-index: 999;
        top: 10px;
        right: 20px;
        background-color: #2fa4e7;
      "
      >新建通知 </el-button
    >
    <div style="clear: both"></div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        :label="item.title"
        v-for="(item, index) in NotificationType"
        :key="index"
        :name="item.id"
      >
        <div style="margin:0 15px">
          <as-list
          v-if="item.id == activeName"
            :ajaxMethods="{ list: 'GetNoticelist' }"
            :listQueryProp="listQueryStudent"
          >
            <!-- <el-table-column prop="dangan" label="排序"></el-table-column> -->
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <!-- <el-table-column prop="weight_int" label="排序"></el-table-column> -->
            <el-table-column prop="Typename" label="类型"></el-table-column>
            <el-table-column
              prop="notice_title"
              label="通知标题"
            ></el-table-column>
            <el-table-column prop="weight_int" label="权重"></el-table-column>
            <el-table-column label="查看权限">
              <template slot-scope="scope">
                <div v-if="scope.row.view_permissions_name && scope.row.view_permissions_name.length">
                  <span >
                    {{ scope.row.view_permissions_name.join(',')}}
                  </span>
                  <!-- <span
                    class="margin-left-5"
                    v-for="(item, index) in scope.row.view_permissions_name"
                    :key="index"
                    >{{ item }}</span --> 
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="Username" label="作者"></el-table-column>
            <el-table-column label="创建时间">
              <template slot-scope="scope">
                <span v-if="scope.row.addtime" class="cursor">
                  {{ scope.row.addtime.split("T")[0] }}</span
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <router-link
                  replace
                  :to="{
                    path: '/InternalManagement/addNotice',
                    query: { id: scope.row.id },
                  }"
                >
                  <span class="cursor text-blue"> 编辑</span>
                </router-link>
                <span
                  class="cursor text-blue margin-left"
                  @click="delstudent(scope.row.id)"
                  >删除</span
                >
              </template>
            </el-table-column>
          </as-list>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import AsList from "@/components/as-list";
export default {
  components: {
    AsList,
  },
  data() {
    return {
      listQueryStudent: {
        KeyWord: "",
        notice_type: "",
        PageIndex: 1,
        PageSize: 10,
      },
      name: ["财务", "校区"],
      NotificationType: [],
      activeName: "全部通知",

      jobList: 1,
    };
  },
  created() {
    this.getNotificationType();
  },
  methods: {
    delstudent(id) {
      this.$confirm("此操作将删除该条通知, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          var ids = {
            ids: id,
            status: 1,
          };
          this.API.NoticeChangeStatus(ids).then((res) => {
            if (res.success) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.listQueryStudent = this.listQueryStudent;
            } else {
              this.$message({
                message: "删除失败！",
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },
    getNotificationType() {
      this.$Dictionary
        .GetDictionaryAll(this.$Dictionary.moduleConfig.NotificationType)
        .then((res) => {
          this.NotificationType = res.data.rows.reverse();
          this.NotificationType = this.NotificationType.filter((x) => {
            return x.parent_id != 0;
          });
          for (let index = 0; index < this.NotificationType.length; index++) {
            this.NotificationType[index].id = JSON.stringify(
              this.NotificationType[index].id
            );
          }
          if (this.NotificationType.length) {
            this.activeName = this.NotificationType[0].id;
            this.listQueryStudent.notice_type = this.activeName;
            this.listQueryStudent.notice_type = parseInt(
              this.listQueryStudent.notice_type
            );
          }
        });
    },

    handleClick(tab, event) {
      this.activeName = this.activeName;
      this.listQueryStudent.notice_type = this.activeName;
      this.listQueryStudent.notice_type = parseInt(
        this.listQueryStudent.notice_type
      );
    },
    JumpUrl(url, id) {
      this.$router.push(url);
    },
  },
};
</script>

<style  scoped>
.color- {
  color: #8cc5ff;
}
.color-box {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
}
.total {
  width: 100%;
}
.TopLabel {
  widows: 200px;
}
.TopLabel_1 {
  widows: 300px;
}
.configure {
  width: 100%;
  background-color: #fff;

  padding: 30px 10px 10px 10px;
}

>>> .is-active {
  height: 43px;
  font-size: 17px;
  font-weight: bold !important;
}
</style>